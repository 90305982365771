/* You can add global styles to this file, and also import other style files */

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";



/* Provide sufficient contrast against white background */

/*html, body {
  height: 100%;
  width: 80%;
  align-content: center;
}*/

/*body {
  width: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}*/


html, body {
  height: 100%;
  width: 80%;
  align-content: center;
  vertical-align: top;
  padding-bottom: 300px;
}

html {
  display: table;
  margin: auto;
}

body {
  display: table-cell;
/*  vertical-align: middle;*/
  width: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  
}


container {
    width: 95%;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn {
  width: 120px;
}

.btn-outline-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.corp-menu {
  text-align: center;
  margin: 0 20% 0 20%;
  display: inline-flex;
}

  .corp-menu li {
    flex-grow: 1;
    border: 1px solid lightgrey;
    border-radius: 10px;
    background-color: lightgrey;
    min-width: 100px;
    text-align: center;
    margin: 0 20px;
  }



h3 {
  margin-top: 10px;
  font-size: 1.5rem;
}

@media (min-width: 1240px) {
  html {
    max-width: 1240px;
  }
  
}